import './App.css';
import LeftPanel from './leftPanel';
import RightPanel from './rightPanel'
import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
function App() {
  return (
    <div className="grid-container">
      <Router>
        <div className="leftSide">
          <LeftPanel />
        </div>
        <div className = "rightSide">
          <Routes>
            <Route exact path = '/' element = {<RightPanel/>}/>
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
