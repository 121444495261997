import './rightPanel.css';
import headshot from './images/headshot.jpg'
import webscraper from './images/webscraper.png'
import recipewebsite from './images/recipewebsite.png'
import sorting from './images/sorting.png'
import onecoolearth from './images/onecoolearth.png'
import bouncybrick from './images/bouncybrick.png'
function RightPanel() {
  return (
    <div className = 'right'>
      <div className = 'intro'> 
        <b>Hi, my name is Archie Jones.</b>
      </div>
      <div className = 'aboutme'>
        <div className = 'text'>
          <b> About Me</b>
          <p>I am a third year Software Engineering major at Cal Poly Slo originally from Maplewood, New Jersey. 
            I discovered my passion for the subject sophomore year of high school, after taking AP Computer Science. 
            I love that Software Engineering gives me the tools to solve problems and that there are no limits on what you can create with these tools. 
            My drive to learn more took me across the country to pursue opportunities at Cal Poly. 
            When not in front of a computer, you can find me surfing at a nearby beach, rock climbing, or strumming a guitar. 
          </p>
        </div>
        <div className = 'photo'>
          <img src = {headshot}  alt = 'no' id = 'headshot'/>
        </div>
      </div>
      <div className = 'projects'>
        <div className = 'header'>
          <b id = "projectHeader">Projects</b>
        </div>
        <div className = 'project' id = 'project1'>
          <div className = 'description' id = "description1">
            <b>Python Webscraping Project</b>
            <p>For this project, I used selenium to scrape information about recently posted potential internship opportunities from websites such as indeed.com and simplyhired.com. 
              From the websites I scraped the title of the role, the company, the date it was posted, the description of the job, and the qualifications needed. 
              <br></br><br></br>Using this information, the script sends myself an email with all the the new listings and details about them. 
              Using crontab I automated this script to run once a day so I am getting emails daily about the latest job listings, saving myself time instead of having to manually search many different websites.</p>
          </div>
          <div className = 'projectLink' id = "link1">
            <a href = 'https://github.com/archie1802/PythonWebscraperApp'>
              <div className = "example">
                <img src={webscraper} alt = "github" className = 'preview' />
                <div className = 'fadedbox'>
                  <div className = 'title text'>Click to view</div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className = 'project' id = 'project2'>
          <div className = 'projectLink' id = "link2">
            <div className = "example">
              <a href= 'http://recipewebsite.archiejonesprojects.com/'>
                <img src={recipewebsite}  alt = "recipewebsite"/>
              <div className = 'fadedbox'>
                  <div className = 'title text'>Click to view</div>
              </div>
              </a>
            </div>
          </div>
          <div className = 'description' id = 'description2'>
            <b>FullStack Grocery Website and API</b>
            <p>This website was made with MERN for the Hack4Impact bootcamp in the end of 2020. 
              It uses react.js for the frontend and express.js, node.js, and mongodb for backend. 
              The website fetches data to put on the website from an API I created which holds recipe information and user-ratings of the recipe.
              (Note: for API to work on your computer you must allow CORS: Access-Control-Allow-Origin in your browser)</p>
          </div>
        </div>
        <div className = 'project' id = 'project3'>
          <div className = 'description' id = "description3">
              <b>Sorting Algorithm Visualizations</b>
              <p>Utilized p5.js, react.js, and bootstrap to develop a website that visualizes four common sorting algorithms: Bubble sort, 
                Selection Sort, Quick sort, and Merge sort.
                Implements perlin noise algorithm to create unique looking tables for the algorithms to sort.</p>
            </div>
            <div className = 'projectLink' id = "link3">
              <a href = 'https://sortingproject.archiejonesprojects.com/'>
                <div className = "example">
                  <img src={sorting} alt = "github" className = 'preview' />
                  <div className = 'fadedbox'>
                    <div className = 'title text'>Click to view</div>
                  </div>
                </div>
              </a>
            </div>
        </div>
        <div className = 'project' id = 'project4'>
          <div className = 'projectLink' id = "link4">
            <div className = "example">
              <a href= 'https://www.onecoolearth.h4i-cp.org/'>
                <img src={onecoolearth}  alt = "onecoolearth"/>
              <div className = 'fadedbox'>
                  <div className = 'title text'>Click to view</div>
              </div>
              </a>
            </div>
          </div>
          <div className = 'description' id = 'description4'>
            <b>Hack4Impact One Cool Earth Page</b>
            <p>This full stack MERN website was created with a small group as our Hack4Impact project for the company One Cool Earth. 
              The website uses google sign in to create and login to accounts and MongoDB to store user information. 
              Specifically, on this project I worked a lot on the backend by helping set up MongoDB as well as creating 
              some of the frontend pages that the user interacts with.(Note: to see the full website you will need to create an 
              account as it is a volunteer account page)</p>
          </div>
        </div>
        <div className = 'project' id = "project5">
          <div className = 'description' id = "description5">
            <b>Bouncy Brick</b>
            <p>A small web game inspired by flappy bird made with javascript, html, and css for my AP Computer Science class in high school.</p>
          </div>
          <div className = 'projectLink' id = "link5">
            <a href = 'http://bouncybrick.archiejonesprojects.com/'>
              <div className = "example">
                <img src={bouncybrick} alt = "bouncybrick" className = 'preview' />
                <div className = 'fadedbox'>
                  <div className = 'title text'>Click to view</div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RightPanel;
