import './leftPanel.css';
import { Link, animateScroll as scroll} from "react-scroll";
function LeftPanel() {
  let scrollToTop = () => {
    scroll.scrollToTop();
  }
  return (
    <div className = 'left'>
      <div className = 'name' onClick={() => scrollToTop()}>
        <b>Archie Jones</b>
        <p>Software Engineer</p>
      </div>
      <div className = 'list'>
          <ul id = "leftList">
          <div className = 'top'>
            <li>
              <div className = 'wrapper'>
                <Link className = 'link' to='intro'
                activeClass = "active"
                spy = {true}
                smooth = {true}>
                  Home
                </Link>
              </div>
            </li>
            <li>
              <div className = 'wrapper'>
                <Link className = 'link' to='aboutme'
                  activeClass = "active"
                  spy = {true}
                  smooth = {true}>
                    About Me
                </Link>
              </div>
            </li>
            <li>
              <div className = 'wrapper'>
                <Link className = 'link' to='projects'
                  activeClass = "active"
                  spy = {true}
                  smooth = {true}
                  >Projects</Link>
              </div>
            </li>
          </div>
          <div className = 'border'>

          </div>
          <div className = 'bottom'>
            <li>
              <div className = 'wrapper'>
                <a className = 'link' href = 'https://drive.google.com/file/d/1Hg7yzyngdqk1xBluJRfZ5OXrx_NeB2lj/view?usp=sharing'>Resume</a>
              </div>
            </li>
            <li>
              <div className = 'wrapper'>
                <a className = 'link' href='https://www.linkedin.com/in/archie-jones-62a89a229'>LinkedIn</a>
              </div>
            </li>
            <li>
              <div className = 'wrapper'>
                <a className = 'link' href='mailto:ajone130@calpoly.edu'>Email</a>
              </div>
            </li>
          </div>
        </ul>
      </div>
    </div>
  );
}

export default LeftPanel;
